import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_VEHICLES,
  CREATE_VEHICLE,
  UPDATE_VEHICLE,
  DELETE_VEHICLE,
  SHOW_UPDATE_VEHICLE_FORM,
  SHOW_CREATE_VEHICLE_FORM,
  SHOW_DELETE_VEHICLE_DIALOG,
  HIDE_UPDATE_VEHICLE_FORM,
  HIDE_CREATE_VEHICLE_FORM,
  HIDE_DELETE_VEHICLE_DIALOG,
  UPDATE_VEHICLE_TABLE_STATE,
  GET_QRCODE,
} from 'constants/ActionTypes';
import mobipixApi from 'util/Api';
import { toast } from 'react-toastify';

const vehicleBaseEndpoint = 'vehicles';

export const getVehicles = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.get(vehicleBaseEndpoint)
      .then(res => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_VEHICLES, payload: res.data });
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao recuperar veículos');
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const createVehicle = (newVehicle) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.post(vehicleBaseEndpoint, newVehicle)
      .then(res => {
        toast.success('Veículo adicionado');
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CREATE_VEHICLE, payload: res.data });
        dispatch({ type: HIDE_CREATE_VEHICLE_FORM });
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao adicionar veículo');
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const updateVehicle = (updatedVehicle) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.put(vehicleBaseEndpoint, updatedVehicle)
      .then(res => {
        toast.success('Veículo editado');
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: UPDATE_VEHICLE, payload: res.data });
        dispatch({ type: HIDE_UPDATE_VEHICLE_FORM });
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao editar veículo');
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const deleteVehicle = (vehicleId) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.delete(vehicleBaseEndpoint)
      .then(_res => {
        toast.success('Veículo excluído');
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: DELETE_VEHICLE, payload: vehicleId });
        dispatch({ type: HIDE_DELETE_VEHICLE_DIALOG });
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao excluir veículo');
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const showUpdateVehicleForm = (vehicleId) => {
  return {
    type: SHOW_UPDATE_VEHICLE_FORM,
    payload: vehicleId,
  };
};

export const showCreateVehicleForm = () => {
  return { type: SHOW_CREATE_VEHICLE_FORM };
};

export const showDeleteVehicleDialog = (vehicleId) => {
  return {
    type: SHOW_DELETE_VEHICLE_DIALOG,
    payload: vehicleId,
  };
};

export const hideUpdateVehicleForm = () => {
  return { type: HIDE_UPDATE_VEHICLE_FORM };
};

export const hideCreateVehicleForm = () => {
  return { type: HIDE_CREATE_VEHICLE_FORM };
};

export const hideDeleteVehicleDialog = () => {
  return { type: HIDE_DELETE_VEHICLE_DIALOG };
};

export const updateVehicleTableState = rowsExpanded => {
  return {
    type: UPDATE_VEHICLE_TABLE_STATE,
    payload: rowsExpanded
  };
};

export const getQRCode = onboardDeviceUuid => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    mobipixApi.get(`onboard-devices/${onboardDeviceUuid}/qrcode`)
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: GET_QRCODE,
          payload: { shownQRCodeOnboardDeviceUuid: onboardDeviceUuid, qrCodeData: data }
        });
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao recuperar o QR code');
        dispatch({ type: FETCH_ERROR });
      });
  };
};
